import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import PaidLeaveTable from "./PaidLeaveTable";
import WaCaresTable from "./WaCaresTable";
import Button from "../Button";
import NumberInput from "../NumberInput";
import content from "./content";
import { DEFAULT_YEAR } from "./formulas";

const DEFAULT_INPUT = "";
const DEFAULT_SUBMITTED_INPUT = 0;
const DISCLAIMER_ID = "premiums-calculator-disclaimer";
const FORM_ID = "premiums-calculator-form";
const YEAR_OPTIONS = [
  {
    value: "2021",
    label: "2021",
  },
  {
    value: "2022",
    label: "2022",
  },
  {
    value: "2023",
    label: "2023",
  },
  {
    value: "2024",
    label: "2024",
  },
];

export default function PremiumsCalculator({ lang }) {
  const inputRef = useRef(null);
  const [input, setInput] = useState(DEFAULT_INPUT);
  const [year, setYear] = useState(DEFAULT_YEAR);
  const [submittedInput, setSubmittedInput] = useState(DEFAULT_SUBMITTED_INPUT);
  const [submittedYear, setSubmittedYear] = useState(DEFAULT_YEAR);

  const { label, heading, description, placeholder } = content[lang];

  function handleSubmit(event) {
    const parsedInput = parseInt(input.replace(/,/g, ""), 10);
    setSubmittedInput(parsedInput);
    setSubmittedYear(year);
    event.preventDefault();
  }

  function handleReset(event) {
    setInput(DEFAULT_INPUT);
    setSubmittedInput(DEFAULT_SUBMITTED_INPUT);
    setYear(DEFAULT_YEAR);
    setSubmittedYear(DEFAULT_YEAR);
    inputRef?.current && inputRef.current.focus();
    event.preventDefault();
  }

  return (
    <section className="m-premium-calculator">
      <header className="m-premium-calculator__header">
        <h2 className="m-premium-calculator__title">{heading}</h2>
        {!!description && <p>{description}</p>}
      </header>
      <form
        id={FORM_ID}
        onSubmit={handleSubmit}
        className="m-premium-calculator__form"
        aria-describedby={DISCLAIMER_ID}
      >
        <NumberInput
          ariaLabel={label.grossPay}
          value={input}
          onChange={setInput}
          placeholder={placeholder}
          inputMode="decimal"
          pattern={null}
          inputRef={inputRef}
          className="m-input--light"
          style={{ width: lang === "es" ? "17.778em" : "8.889em" }} // wider for ES to accommodate longer placeholder
        />
        <div className="m-select">
          <select
            aria-label={label.year}
            value={year}
            onChange={(event) => setYear(event.target.value)}
            className="m-select__input m-select__input--narrow"
          >
            {YEAR_OPTIONS.map((year) => (
              <option
                key={year.value}
                value={year.value}
                selected={year.selected}
              >
                {year.label}
              </option>
            ))}
          </select>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            width="20"
            height="20"
            className="a-icon m-select__icon"
            aria-hidden="true"
          >
            <path d="M10 15.174L2.026 7.202l2.375-2.375 5.6 5.6 5.595-5.6 2.375 2.375z" />
          </svg>
        </div>
        <Button
          label={label.calculate}
          type="submit"
          disabled={!input}
          onClick={() => {}}
        />
      </form>
      <PaidLeaveTable
        input={submittedInput}
        year={submittedYear}
        lang={lang}
        DISCLAIMER_ID={DISCLAIMER_ID}
      />
      <WaCaresTable input={submittedInput} year={submittedYear} lang={lang} />
      <footer className="m-premium-calculator__footer">
        <Button
          label={label.reset}
          onClick={handleReset}
          disabled={!submittedInput}
          form={FORM_ID}
          type="reset"
        />
      </footer>
    </section>
  );
}

PremiumsCalculator.propTypes = {
  lang: PropTypes.oneOf(["en", "es"]),
};

PremiumsCalculator.defaultProps = {
  lang: "en",
};
