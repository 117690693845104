import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const NumberInput = ({
  ariaLabel,
  disabled,
  onChange,
  placeholder,
  inputMode = "decimal",
  pattern,
  inputRef,
  className,
  ...restProps
}) => {
  const regex = inputMode === "decimal" ? /^[,\d]*(\.\d{0,2})?$/ : /^\d*$/;

  const handleChange = ({ target: { value } }) => {
    if (regex.test(value)) {
      onChange(value);
    }
  };

  return (
    <input
      type="text"
      inputMode={inputMode}
      pattern={pattern}
      aria-label={ariaLabel}
      disabled={disabled}
      onChange={handleChange}
      placeholder={placeholder}
      className={classNames({
        "m-input": true,
        [`${className}`]: !!className,
      })}
      ref={inputRef}
      {...restProps}
    />
  );
};

NumberInput.propTypes = {
  ariaLabel: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  inputMode: PropTypes.oneOf(["numeric", "decimal"]),
  pattern: PropTypes.string,
  inputRef: PropTypes.object,
  className: PropTypes.string,
};

NumberInput.defaultProps = {
  pattern: "d*",
};

export default NumberInput;
