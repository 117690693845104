import Sb from "../abstract/StatefulBehavior";
import classNames from "classnames";

export default class ToggleSubmenu extends Sb {
  constructor(el, props, refs) {
    super();

    this.state = {
      open: false,
    };

    this.props = props;
    this.refs = refs;
    this.initToggleClass = this.toggleWrapper.className;
    this.initMenuClass = refs.menu.className;

    this.setSubmenuZIndex();
    this.update();
    this.bindToggles();
  }

  get focusable() {
    return Array.from(
      this.refs.menu.querySelectorAll(
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])' // eslint-disable-line max-len
      )
    );
  }

  get toggleWrapper() {
    return this.refs.toggleWrapper || this.refs.toggle;
  }

  get isIE() {
    return !!window.document.documentMode;
  }

  setSubmenuZIndex() {
    /*
    IE11 doesn't understand that `.m-header` has its own stacking context, so
    using a negative value (which would be expected to stack the submenu beneath
    the header) actually puts the subnav underneath `<body>`. To solve this,
    we assign a positive z-index value only in the case of the user agent being
    IE
    */
    if (!this.isIE) return null;
    Object.assign(this.refs.menu.style, { zIndex: 5 });
  }

  update = () => {
    const { toggle, menu } = this.refs;
    const { open } = this.state;

    this.toggleWrapper.className = classNames(this.initToggleClass, {
      "is-untoggled": !open,
      "is-toggled": open,
    });
    toggle.setAttribute("aria-expanded", open ? "true" : "false");

    menu.className = classNames(this.initMenuClass, {
      "is-hidden": !this.state.open,
      "is-visible": this.state.open,
    });

    // disable/enable keyboard tabbing based on state.open
    [].forEach.call(this.focusable, (el) => {
      el.setAttribute("tabindex", this.state.open ? 0 : -1);
    });

    // Ask parent to close siblings when open
    open && this.props.onOpen(this.props.id);
  };

  bindToggles() {
    const { toggle, backToggle } = this.refs;

    toggle.addEventListener("click", (event) => {
      event.preventDefault();
      event.stopPropagation();
      this.state.open ? this.activelyCloseMenu() : this.openMenu();
    });

    backToggle &&
      backToggle.addEventListener("click", (event) => {
        event.preventDefault();
        event.stopPropagation();
        this.activelyCloseMenu();
      });
  }

  trapFocus() {
    if (!this.state.open || !this.focusable || !this.focusable.length) return;

    this.refs.menu.addEventListener("transitionend", this.handleTransitionEnd);
  }

  untrapFocus() {
    if (this.state.open || !this.focusable || !this.focusable.length) return;
    this.refs.toggle.focus();
  }

  handleKeyDown = (event) => {
    event.stopPropagation();

    this.handleEscape(event);
    this.handleTab(event);
  };

  handleMenuClick = (event) => {
    const menuSelector = ".m-subnav.is-visible";
    if (!event.target.closest(menuSelector)) this.closeMenu();
  };

  handleTransitionEnd = (event) => {
    if (event.target !== this.refs.menu) return null;
    this.focusable[0].focus();
  };

  handleEscape({ key, keyCode, which }) {
    if (key === "Escape" || key === "Esc" || keyCode === 27 || which === 27) {
      this.activelyCloseMenu();
    }
  }

  handleTab({ key, keyCode, shiftKey }) {
    const isTabPressed = key === "Tab" || keyCode === 9;
    if (!isTabPressed) return;

    const focusable = [this.refs.toggle].concat(this.focusable);

    // On keydown, this is the last focused item, not the current
    const focusedItem = document.activeElement;
    const focusableIndex = focusable.indexOf(focusedItem);

    const lastIndex = shiftKey
      ? focusableIndex === 0
      : focusableIndex === focusable.length - 1;
    if (!lastIndex) return;

    // Prevent the next tab and set focus to first/last of focusable elements
    event.preventDefault();
    const nextFocus = shiftKey ? focusable[focusable.length - 1] : focusable[0];

    nextFocus.focus();
  }

  openMenu = () => {
    window.addEventListener("keydown", this.handleKeyDown);
    window.addEventListener("click", this.handleMenuClick);
    this.setState({ open: true });
    this.trapFocus();
  };

  closeMenu = () => {
    window.removeEventListener("keydown", this.handleKeyDown);
    window.removeEventListener("click", this.handleMenuClick);
    this.setState({ open: false });
  };

  activelyCloseMenu = () => {
    this.closeMenu();
    this.untrapFocus();
    this.props.onActiveClose();
  };
}
