import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import NumberInput from "../NumberInput";
import RadioGroup from "../RadioGroup";
import {
  hourlyFixedToQuarterly,
  yearlyToQuarterly,
  topQuarterlyToQuarterly,
} from "./formulas";
import content from "./content";

const defaultState = {
  wage: "",
  hours: "",
  topQuarters: "",
  total: "",
};

export default class EarningsInput extends PureComponent {
  static displayName = "WageEstimator.EarningsInput";

  static propTypes = {
    status: PropTypes.oneOf([
      "hourlyFixed",
      "hourlyVariable",
      "salary",
      "self",
      "",
    ]).isRequired,
    activeOption: PropTypes.oneOf(["", "1", "2"]),
    onRadioChange: PropTypes.func.isRequired,
    onTotalChange: PropTypes.func.isRequired,
    lang: PropTypes.oneOf(["en", "es"]),
  };

  state = defaultState;

  componentDidUpdate(prevProps, prevState) {
    const { wage, hours, topQuarters } = this.state;

    if (this.isHourlyUpdate(prevState)) {
      this.props.onTotalChange(hourlyFixedToQuarterly(wage, hours));
    }

    if (this.isQuarterlyUpdate(prevState)) {
      this.props.onTotalChange(topQuarterlyToQuarterly(topQuarters));
    }
  }

  get content() {
    return content[this.props.lang];
  }

  get radioLabel() {
    return this.content.label.option;
  }

  get totalInputPre() {
    return this.content.stepTwo.totalInputPre;
  }

  get totalInputPost() {
    const { totalInputPost } = this.content.stepTwo;
    if (this.props.status === "hourlyVariable")
      return totalInputPost.hourlyVariable;
    return totalInputPost.fallback;
  }

  isHourlyUpdate(prevState) {
    const { wage, hours } = this.state;
    return (
      this.props.activeOption === "2" &&
      (prevState.wage !== wage || prevState.hours !== hours)
    );
  }

  isQuarterlyUpdate(prevState) {
    return prevState.topQuarters !== this.state.topQuarters;
  }

  handleWageChange = (value) => {
    this.setState({ wage: value });
  };

  handleHoursChange = (value) => {
    this.setState({ hours: value });
  };

  handleQuarterlyChange = (value) => {
    this.setState({ topQuarters: value });
  };

  handleTotalChange = (value) => {
    this.setState({ total: value });
    this.props.onTotalChange(yearlyToQuarterly(value));
  };

  handleRadioChange = (value) => {
    this.setState(defaultState);
    this.props.onRadioChange(value);
  };

  renderQuarterlyInput() {
    return (
      <p>
        {this.totalInputPre}{" "}
        <span className="t-whitespace-nowrap">
          $
          <NumberInput
            ariaLabel="Earnings for top two quarters"
            onChange={this.handleQuarterlyChange}
            value={this.state.topQuarters}
            style={{ maxWidth: 200 }}
          />
        </span>{" "}
        {this.totalInputPost}
      </p>
    );
  }

  renderTotalInput(disabled) {
    return (
      <p>
        {this.totalInputPre}{" "}
        <span className="t-whitespace-nowrap">
          $
          <NumberInput
            ariaLabel="Total earnings"
            onChange={this.handleTotalChange}
            disabled={disabled}
            value={this.state.total}
            style={{ maxWidth: 200 }}
          />
        </span>{" "}
        {this.totalInputPost}
      </p>
    );
  }

  render() {
    const { status, activeOption } = this.props;

    const useTotal = status === "salary" || status === "self";
    const useQuarterly = status === "hourlyVariable";
    const { wage, hours, total } = this.state;

    if (useTotal) return this.renderTotalInput(false);
    if (useQuarterly) return this.renderQuarterlyInput(false);

    const radioOptions = [
      {
        value: "1",
        label: `${this.content.label.option} 1:`,
        children: this.renderTotalInput(activeOption !== "1"),
      },
      {
        value: "2",
        label: `${this.content.label.option} 2:`,
        children: (
          <>
            <p>
              {this.content.stepTwo.wageInputPre}{" "}
              <span className="t-whitespace-nowrap">
                $
                <NumberInput
                  ariaLabel="Hourly wage"
                  onChange={this.handleWageChange}
                  disabled={activeOption !== "2"}
                  value={wage}
                  style={{ maxWidth: 125 }}
                />
                .
              </span>
            </p>
            <p>
              {this.content.stepTwo.hoursInputPre}{" "}
              <NumberInput
                ariaLabel="Hours per week"
                onChange={this.handleHoursChange}
                disabled={activeOption !== "2"}
                value={hours}
                style={{ maxWidth: 100 }}
              />{" "}
              {this.content.stepTwo.hoursInputPost}
            </p>
          </>
        ),
      },
    ];

    return (
      <RadioGroup
        name="reportingType"
        options={radioOptions}
        active={activeOption}
        onChange={this.handleRadioChange}
      />
    );
  }
}
