/**
 * Example:
<Button
  color="white"
  ariaControls={REGION_ID}
  onClick={onClick}
  label="Start Here"
/>
 */
import React from "react";
import PropTypes from "prop-types";
import ArrowIcon from "./ArrowIcon";

const Button = ({
  ariaControls = "",
  arrowLeft,
  arrowRight,
  color = "dark-blue",
  disabled,
  label,
  type = "button",
  onClick,
  form,
}) => {
  return (
    <button
      className={`m-button m-button--${color}`}
      onClick={onClick}
      disabled={disabled}
      aria-controls={ariaControls}
      type={type}
      form={form}
    >
      {arrowLeft && <ArrowIcon isLeft />}
      <span>{label}</span>
      {arrowRight && <ArrowIcon />}
    </button>
  );
};

Button.propTypes = {
  ariaControls: PropTypes.string,
  arrowLeft: PropTypes.bool,
  arrowRight: PropTypes.bool,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  form: PropTypes.string,
};

export default Button;
