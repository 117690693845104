import React from "react";
import PropTypes from "prop-types";
import { Table, Head, Row, Cell } from "./parts";
import content from "./content";
import { baseToEmployeeShare, getEmployerMedicalShare } from "./formulas";

export default function PaidLeaveTable({ input, year, lang, DISCLAIMER_ID }) {
  const employeeShareFamily = baseToEmployeeShare(input, "family", year);
  const employeeShareMedical = baseToEmployeeShare(input, "medical", year);
  const employeeShareTotal = employeeShareFamily + employeeShareMedical;

  const employerShareFamily = 0;
  const employerShareMedical = getEmployerMedicalShare(input, year);
  const employerShareTotal = employerShareFamily + employerShareMedical;

  const { label, premium, disclaimer } = content[lang];

  return (
    <Table
      type="paidLeave"
      caption={
        <>
          <h3>{label.paidLeaveTable}</h3>
          <p id={DISCLAIMER_ID}>{disclaimer}</p>
        </>
      }
    >
      <Head columnHeaders={[label.sum, label.employer, label.employee]} />
      <tbody>
        <Row header={premium.family}>
          <Cell value={employerShareFamily + employeeShareFamily} />
          <Cell value={employerShareFamily} />
          <Cell value={employeeShareFamily} />
        </Row>
        <Row header={premium.medical}>
          <Cell value={employerShareMedical + employeeShareMedical} />
          <Cell value={employerShareMedical} />
          <Cell value={employeeShareMedical} />
        </Row>
      </tbody>
      <tfoot>
        <Row header={premium.totalPaidLeave}>
          <Cell value={employerShareTotal + employeeShareTotal} />
          <Cell value={employerShareTotal} />
          <Cell value={employeeShareTotal} />
        </Row>
      </tfoot>
    </Table>
  );
}

PaidLeaveTable.displayName = "PremiumsCalculator.PaidLeaveTable";

PaidLeaveTable.propTypes = {
  input: PropTypes.number.isRequired,
  year: PropTypes.oneOf(["2021", "2022", "2023"]),
  lang: PropTypes.oneOf(["en", "es"]),
  DISCLAIMER_ID: PropTypes.string,
};
