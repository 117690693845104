import React, { useState, useEffect } from "react";

export default function useQualifiedStatus(isQualified) {
  const [value, setValue] = useState("");

  useEffect(() => {
    if (isQualified === false) {
      setValue("");
    }
  }, [isQualified]);

  return [value, setValue];
}
