/*
Creates and updates the values of CSS custom properties from DOM measurements
*/
import debounce from "lodash/debounce";

export default class SetCSSProperties {
  constructor() {
    this.setProperties();
    this.bindResize();
  }

  setProperties() {
    this.setAdminBarHeight();
    this.setHeaderHeight();
  }

  setAdminBarHeight() {
    const wpAdminBar = document.querySelector("#wpadminbar");
    if (!wpAdminBar) return;
    const adminBarHeight = wpAdminBar.getBoundingClientRect().height;
    this.setCSSProperty("--admin-bar-height", `${adminBarHeight}px`);
  }

  setHeaderHeight() {
    const header = document.querySelector("#site-header");
    if (!header) return;
    const headerHeight = header.getBoundingClientRect().height;
    this.setCSSProperty("--site-header-height", `${headerHeight}px`);
  }

  bindResize() {
    const debouncedClientSize = debounce(() => this.setProperties(), 15);
    window.addEventListener("resize", debouncedClientSize);
  }

  setCSSProperty(name, value) {
    document.documentElement.style.setProperty(name, value);
  }
}
