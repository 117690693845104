export default {
  en: {
    label: {
      calculate: `Estimate`,
      reset: `Reset`,
      sum: `Sum`,
      employer: `Employer`,
      employee: `Employee`,
      paidLeaveTable: `Paid Leave`,
      waCaresTable: `WA Cares`,
      grossPay: "Gross pay",
      year: "Year",
    },
    heading: `Estimate your premiums`,
    description: `Select the correct year and then click “estimate”.`,
    placeholder: `Enter gross payroll`,
    disclaimer: `If you have employees whose gross wages are over the Social Security wage cap, this estimator may overestimate your total Paid Leave premiums.`,
    waCaresDisclaimer: `WA Cares premiums apply to wages paid starting July 1, 2023.`,
    premium: {
      family: `Family Leave Premium`,
      medical: `Medical Leave Premium`,
      waCares: `WA Cares Premium`,
      totalPaidLeave: `Total Paid Leave Premium`,
      totalWaCares: `Total WA Cares Premium`,
    },
  },
  es: {
    label: {
      calculate: `Calcular`,
      reset: `Reiniciar`,
      sum: `Monto`,
      employer: `Patrón`,
      employee: `Empleado`,
      paidLeaveTable: `Permiso de cuidado pagado`,
      waCaresTable: `WA Cares`,
      grossPay: "Nómina en bruto",
      year: "Año",
    },
    heading: `Calcule sus primas`,
    description: `Seleccione el año correcto y luego haga clic en calcular.`,
    placeholder: `Ingrese la cantidad de la nómina en bruto`,
    disclaimer: `Si tiene empleados cuyos salarios brutos están por encima del límite salarial del Seguro Social, esta calculadora puede sobrestimar sus primas totales del Permiso de cuidado pagado.`,
    waCaresDisclaimer: `Las primas de WA Cares se aplican a los salarios pagados a partir del 1 de julio de 2023.`,
    premium: {
      family: `Prima de permiso familiar`,
      medical: `Prima de permiso médico`,
      waCares: `Prima de WA Cares`,
      totalPaidLeave: `Prima total del Permiso de cuidado pagado`,
      totalWaCares: `Prima total de WA Cares`,
    },
  },
};
