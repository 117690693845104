import React from "react";
import PropTypes from "prop-types";

function Row({ header, children }) {
  return (
    <>
      <tr>
        <th
          scope="row"
          colSpan="3"
          className="m-premium-calculator__table-cell m-premium-calculator__table-cell--mobile"
        >
          {header}
        </th>
      </tr>
      <tr>
        <th
          scope="row"
          className="m-premium-calculator__table-cell m-premium-calculator__table-cell--desktop"
        >
          {header}
        </th>
        {children}
      </tr>
    </>
  );
}

Row.displayName = "PremiumsCalculator.Table.Row";

Row.propTypes = {
  header: PropTypes.string,
  children: PropTypes.node,
};

export default Row;
