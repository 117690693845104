import React from "react";
import PropTypes from "prop-types";
import { Table, Head, Row, Cell } from "./parts";
import content from "./content";
import { getEmployeeWaCaresShare } from "./formulas";

export default function WaCaresTable({ input, year, lang }) {
  const employeeShare = getEmployeeWaCaresShare(input, year);

  const { label, premium, waCaresDisclaimer } = content[lang];

  return (
    <Table
      type="waCares"
      caption={
        <>
          <h3>{label.waCaresTable}</h3>
          <p>{waCaresDisclaimer}</p>
        </>
      }
    >
      <Head columnHeaders={[label.sum, label.employer, label.employee]} />
      <tbody>
        <Row header={premium.waCares}>
          <Cell value="—" />
          <Cell value="—" />
          <Cell value={employeeShare} />
        </Row>
      </tbody>
      <tfoot>
        <Row header={premium.totalWaCares}>
          <Cell value="—" />
          <Cell value="—" />
          <Cell value={employeeShare} />
        </Row>
      </tfoot>
    </Table>
  );
}

WaCaresTable.displayName = "PremiumsCalculator.WaCaresTable";

WaCaresTable.propTypes = {
  input: PropTypes.number.isRequired,
  year: PropTypes.oneOf(["2021", "2022", "2023"]),
  lang: PropTypes.oneOf(["en", "es"]),
};
