/*
SelectDownload

Sets a link href value to the selected option value.

Usage:
<div data-behavior="SelectDownload">
  <select data-ref="downloadSelect" aria-label="Select language">
      <option value="file_en.pdf">English</option>
      <option value="file_es.pdf">Spanish</option>
  </select>
  <a
    href=""
    data-ref="downloadButton"
    class="m-button"
    target="_blank"
    download
  >
    Download
  />
</div>
*/
import Sb from "../abstract/StatefulBehavior";
import classNames from "classnames";

export default class SelectDownload extends Sb {
  constructor(el, props, refs) {
    super();
    this.state = {
      value: "",
    };

    this.refs = refs;
    this.disabledClass = "m-button--is-disabled";

    this.bindEvents();
  }

  bindEvents() {
    const { downloadSelect } = this.refs;

    downloadSelect.addEventListener("change", this.onSelect);
  }

  onSelect = ({ target }) => {
    const { downloadButton } = this.refs;
    const { value } = target;

    downloadButton.setAttribute("href", value);

    if (value === "") {
      downloadButton.classList.add(this.disabledClass);
    } else {
      downloadButton.classList.remove(this.disabledClass);
    }
  };
}
