import content from "./content";

export function yesNo(lang) {
  return [
    {
      value: "true",
      label: content[lang].label.yes,
    },
    {
      value: "false",
      label: content[lang].label.no,
    },
  ];
}

export function eventTime(lang) {
  return [
    {
      value: "true",
      label: content[lang].label.past,
    },
    {
      value: "false",
      label: content[lang].label.future,
    },
  ];
}

export function buttonLabel(lang, key) {
  return content[lang].label[key];
}
