import React, { useState } from "react";
import PropTypes from "prop-types";
import PlusIcon from "./PlusIcon";

const Accordion = ({ children, open = false, title }) => {
  return (
    <details open={open} className="m-accordion-small">
      <summary role="button">
        <div className="m-accordion-small__summary">
          <span className="m-accordion-small__title">{title}</span>
          <div className="m-accordion-small__icon-mobile">
            <PlusIcon small className="m-accordion-small__icon" />
          </div>
        </div>
      </summary>
      <div className="m-accordion-small__body">{children}</div>
    </details>
  );
};

Accordion.propTypes = {
  children: PropTypes.node.isRequired,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

export default Accordion;
