/*
LazyLoad

Tests for native lazy-loading support, and if not detected,
runs IntersectionObserver to detect when target images scroll into view.
Then sets image's `src` and `srcset` attributes with `data-src` and
`data-srcset` values and stops observing.

Based on https://developers.google.com/web/fundamentals/performance/
lazy-loading-guidance/images-and-video/#inline_images

Requires intersection-observer-polyfill (https://github.com/w3c/
IntersectionObserver/tree/master/polyfill) to work in older browsers.

Usage:
   <img
     data-src="image-1x.jpg"
     data-srcset="image-2x.jpg 2x, image-1x.jpg 1x"
     data-objectfit="true" // Run ObjectFit behavior after intersection
     alt="I'm an image!"
     loading="lazy"
     width="500"
     height="300"
   />
*/
import Percolator from "../../helpers/Percolator";
import ObjectFit from "./ObjectFit";

export default class LazyLoad {
  constructor(el) {
    this.images = el.querySelectorAll("img[loading='lazy']");

    if (!this.images) return null;

    this.detectFeature();
  }

  detectFeature() {
    const detected = "loading" in HTMLImageElement.prototype;
    detected ? this.onlySetSrc() : this.runObserver();
  }

  onlySetSrc() {
    this.images.forEach((image) => {
      image.srcset = this.dataSrcset(image);
      image.src = this.dataSrc(image);
      image.classList.add("is-loaded");
      !!image.dataset.objectfit &&
        this.runObjectFit(image, image.dataset.objectfit);
    });
  }

  runObserver() {
    const options = {
      rootMargin: "0px 0px 100px 0px",
    };

    this.observer = new IntersectionObserver(this.onIntersection, options);

    this.images.forEach((image) => {
      image.classList.add("is-loading");
      this.observer.observe(image);
    });
  }

  onIntersection = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const lazyImage = entry.target;
        lazyImage.src = this.dataSrc(lazyImage);
        lazyImage.srcset = this.dataSrcset(lazyImage);
        lazyImage.classList.remove("is-loading");
        lazyImage.classList.add("is-loaded");
        this.observer.unobserve(lazyImage);
        !!lazyImage.dataset.objectfit &&
          this.runObjectFit(lazyImage, lazyImage.dataset.objectfit);
      }
    });
  };

  runObjectFit(image) {
    const percolator = new Percolator();
    percolator.loadBehavior(image, ObjectFit);
  }

  dataSrcset(image) {
    return image.dataset.srcset || this.dataSrc(image);
  }

  dataSrc(image) {
    return image.dataset.src || "";
  }
}
