import BenefitsCalculator from "./BenefitsCalculator";
import PremiumsCalculator from "./PremiumsCalculator";
import QualifyQuiz from "./QualifyQuiz";
import VideoPlayer from "./VideoPlayer";

export default {
  BenefitsCalculator,
  PremiumsCalculator,
  QualifyQuiz,
  VideoPlayer,
};
