export default class StatefulBehavior {
  constructor() {
    this.state = {};
  }

  setState(next, callback = this.update) {
    this.state = Object.assign({}, this.state, next);

    if (callback) {
      callback();
    }
  }
}
