import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

function typeToModifier(type) {
  switch (type) {
    case "paidLeave":
      return "paid-leave";
    case "waCares":
      return "wa-cares";
    default:
      return "";
  }
}

function BaseTable({ caption, type, children }) {
  return (
    <table
      aria-live="polite"
      aria-atomic
      className={classNames({
        "m-premium-calculator__table": true,
        [`m-premium-calculator__table--${typeToModifier(type)}`]: !!type,
      })}
    >
      <caption className="m-premium-calculator__caption">{caption}</caption>
      {children}
    </table>
  );
}

BaseTable.displayName = "PremiumsCalculator.Table";

BaseTable.propTypes = {
  caption: PropTypes.node,
  type: PropTypes.oneOf(["paidLeave", "waCares"]),
  children: PropTypes.node,
};

export default BaseTable;
