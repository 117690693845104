import React, { useState } from "react";
import PropTypes from "prop-types";

const RadioGroup = ({ name, options, active, onChange }) => {
  return (
    <div role="group" className="m-quiz__radio-group">
      {options.map((option) => (
        <React.Fragment key={option.value}>
          <label className="m-radio">
            <input
              type="radio"
              value={option.value}
              name={name}
              checked={option.value === active}
              onChange={(event) => onChange(event.target.value)}
              className="m-radio__input"
            />
            <span className="m-radio__indicator" />
            <span>{option.label}</span>
          </label>
          {option.children}
        </React.Fragment>
      ))}
    </div>
  );
};

RadioGroup.propTypes = {
  active: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default RadioGroup;
