import React from "react";
import PropTypes from "prop-types";

const ArrowIcon = ({ isLeft }) => {
  const className = isLeft
    ? "m-button__icon--pre a-icon--rotate-180"
    : "m-button__icon--post";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      width="20"
      height="20"
      className={`a-icon ${className}`}
      aria-hidden="true"
    >
      <path d="M14.922 9.884l-7.655-8.18-2.189 2.051L10.923 10l-5.845 6.245 2.189 2.051 7.655-8.179-.124-.117.124-.116z" />
    </svg>
  );
};

ArrowIcon.propTypes = {
  isLeft: PropTypes.bool,
};

export default ArrowIcon;
