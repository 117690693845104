import React from "react";
import PropTypes from "prop-types";
import Button from "../Button";
import { buttonLabel } from "./helpers";
import { langShape, contentShape } from "./shapes";
import { REGION_ID } from "./constants";

const Intro = ({ onClick, content, lang }) => {
  return (
    <div id="intro" className="m-quiz__intro" aria-live="polite">
      <h3 className="m-quiz__intro-title">{content.heading}</h3>
      <div className="m-quiz__intro-body">
        <p>{content.body}</p>
      </div>
      <Button
        color="white"
        ariaControls={REGION_ID}
        onClick={onClick}
        label={buttonLabel(lang, "start")}
        type="submit"
      />
    </div>
  );
};

Intro.propTypes = {
  ...langShape,
  ...contentShape,
  onClick: PropTypes.func.isRequired,
};

export default Intro;
