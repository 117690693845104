import React from "react";
import StepWrapper from "../StepWrapper";
import RadioGroup from "../RadioGroup";
import Button from "../Button";
import Accordion from "./Accordion";
import { yesNo, buttonLabel } from "./helpers";
import { quizStepShape } from "./shapes";
import { REGION_ID, TOTAL_STEPS } from "./constants";
import useQualifiedStatus from "./useQualifiedStatus";

const QFamily = ({
  isQualified,
  onBack,
  onSubmit,
  step,
  stepCurrent,
  visualStep,
  visualStepCurrent,
  lang,
  content,
}) => {
  const [value, setValue] = useQualifiedStatus(isQualified);

  const handleBack = () => {
    onBack();
  };

  const handleNext = () => {
    if (value === "false") {
      onSubmit({
        isQualified: false,
        message: content.dqMessage,
      });
    } else {
      onSubmit({
        step: step + 1,
        visualStep: visualStep + 1,
      });
    }
  };

  return (
    <StepWrapper
      ariaControls={REGION_ID}
      title={`Q${visualStep}. ${content.heading}`}
      step={step}
      stepCurrent={stepCurrent}
      visualStep={visualStep}
      visualStepCurrent={visualStepCurrent}
      stepTotal={TOTAL_STEPS}
      buttons={
        <>
          <Button
            arrowLeft
            onClick={handleBack}
            label={buttonLabel(lang, "back")}
          />
          <Button
            arrowRight
            onClick={handleNext}
            disabled={value === ""}
            label={buttonLabel(lang, "next")}
            type="submit"
          />
        </>
      }
    >
      {content.accordions.map((accordion, index) => (
        <Accordion key={index} title={accordion.heading}>
          {accordion.body}
        </Accordion>
      ))}
      <RadioGroup
        name="familyMember"
        options={yesNo(lang)}
        active={value}
        onChange={(value) => setValue(value)}
      />
    </StepWrapper>
  );
};

QFamily.propTypes = {
  ...quizStepShape,
};

export default QFamily;
