import React from "react";

export default {
  label: {
    back: `Atrás`,
    next: `Siguiente`,
    yes: "Sí",
    no: "No",
    close: "Cerrar",
    past: "Ya ocurrió",
    future: "Ocurrirá pronto",
    start: "Iniciar aquí",
    restart: "Comenzar de nuevo",
    applyNow: "Solicitar permiso pagado",
    applyFuture: "Preparación para solicitar el permiso",
    learn: "Más información",
  },
  intro: {
    heading: `Antes de solicitar un Permiso de cuidado pagado,
      es importante que verifique si cumple con todos los requisitos
      para solicitarlo.`,
    body: `Responda estas breves preguntas para ver si ya
      es el momento adecuado para solicitar su permiso.`,
  },
  qWork: {
    heading: `¿Trabajó en el estado de Washington en los últimos 18 meses?`,
    dqMessage: `Lo sentimos, pero el Permiso de cuidado pagado solo está 
      disponible para las personas que trabajaron en Washington en los últimos 
      18 meses. Si no trabaja en este estado, no puede solicitar un permiso 
      pagado.`,
  },
  qEvent: {
    heading: `¿Está pasando actualmente por alguno de los siguientes
      “eventos calificadores”? O bien, ¿sabe que alguno de ellos ocurrirá
      pronto? (Haga clic en el signo “+” que está junto a cada una de las
      opciones para ver más información).`,
    accordions: [
      {
        heading: `Una lesión o padecimiento de salud fuerte`,
        body: (
          <p>
            Una enfermedad, lesión, padecimiento físico o mental que le impide
            trabajar. (Cirugías mayores, enfermedades como el cáncer y
            tratamientos por abuso de sustancias en los que debe internarse en
            un centro especializado son ejemplos de problemas de salud fuertes).
          </p>
        ),
      },
      {
        heading: `Recibir a un nuevo hijo a su familia`,
        body: (
          <p>
            Esto incluye el nacimiento de hijos biológicos y recibir a hijos
            adoptivos o en custodia temporal en su familia.
          </p>
        ),
      },
      {
        heading: `Un familiar suyo es militar y pronto saldrá o volverá de un
          despliegue en el extranjero`,
        body: (
          <p>
            Si un familiar suyo es miembro de un cuerpo militar que será
            desplegado al exterior o que vuelve a casa después de un despliegue
            para descansar y recuperarse, usted puede pedir un permiso pagado
            para pasar tiempo con la persona.
          </p>
        ),
      },
    ],
  },
  qFamily: {
    heading: `¿Tiene un familiar con una lesión o problema de salud fuerte?
      O bien ¿tiene un familiar a quien se le programó una cirugía mayor o
      evento médico similar?`,
    dqMessage: `El Permiso de cuidado pagado es para cuando necesita tiempo
        libre para cuidar de usted mismo o de un familiar, para pasar tiempo
        con un nuevo hijo o pasar tiempo con un miembro de su familia que se
        esté preparando para un despliegue militar en el extranjero. Parece
        que no cumple con los requisitos para solicitar un Permiso pagado.`,
    accordions: [
      {
        heading: `Familiar`,
        body: (
          <>
            <p>
              Para efectos del Permiso de cuidado pagado, se considera como
              familiar a las siguientes personas:
            </p>
            <ul>
              <li>Su cónyuge o pareja de hecho</li>
              <li>
                Hijos (sean biológicos, adoptivos, en custodia o hijastros)
              </li>
              <li>Padres o tutores (los suyos y los de su cónyuge)</li>
              <li>Hermanos</li>
              <li>Nietos</li>
              <li>Abuelos (los suyos y los de su cónyuge)</li>
              <li>
                Alguien que espera confiar en usted para recibir cuidado, ya sea
                que vivan juntos o no.
              </li>
            </ul>
          </>
        ),
      },
      {
        heading: `Problema de salud fuerte`,
        body: (
          <p>
            Una enfermedad, lesión o padecimiento físico o mental que impida
            trabajar a una persona. (Cirugías mayores, enfermedades como el
            cáncer y tratamientos por abuso de sustancias en los que debe
            internarse en un centro especializado son ejemplos de problemas de
            salud fuertes).
          </p>
        ),
      },
    ],
  },
  qApply: {
    heading: `¿Alguna de las siguientes opciones se aplica a usted?`,
    body: (
      <>
        <p>No trabajé para ningún otro empleador de Washington y:</p>
        <ul>
          <li>Trabajé para un empleador federal en los últimos 18 meses.</li>
          <li>
            Mi patrón me ofrece beneficios de permiso de cuidado pagado por
            medio de un plan voluntario aprobado
          </li>
          <li>
            Trabajo por mi cuenta y no me he inscrito en el programa de Permiso
            de cuidado pagado
          </li>
          <li>
            Trabajo para una tribu reconocida federalmente o en un negocio
            propiedad del gobierno de una tribu reconocida federalmente que no
            está inscrito en el plan estatal
          </li>
          <li>
            Trabajo para un negocio que se ubica dentro de un territorio tribal
            y es propiedad de un miembro de una tribu reconocida federalmente
          </li>
        </ul>
      </>
    ),
    accordions: [
      {
        heading: `Tribu reconocida federalmente o negocio propiedad del
          gobierno de una tribu reconocida federalmente.`,
        body: (
          <p>
            Los gobiernos tribales necesitan inscribirse en el programa para que
            sus empleados y los empleados de negocios de su propiedad reciban
            permisos pagados.
          </p>
        ),
      },
      {
        heading: `Negocio que se ubica dentro de un territorio tribal y es
          propiedad de un miembro de una tribu reconocida federalmente`,
        body: (
          <p>
            Los empleados de estos negocios no son elegibles para recibir
            Permisos de cuidado pagados a través del plan estatal.
          </p>
        ),
      },
    ],
    dqMessage: `Lo sentimos, pero parece que trabaja para un patrón que no
      está cubierto por el Permiso de cuidado pagado.`,
  },
  qHours: {
    heading: `¿Trabajó por lo menos 820 horas en el período calificador? 
      (Puede haber trabajado las 820 horas en un solo lugar o en varios 
      trabajos).`,
    body: (
      <>
        <p>
          Obtenga más información sobre{" "}
          <a
            href="/question/qualifying-period/"
            target="_blank"
            rel="noreferrer noopener"
          >
            el período calificador
          </a>
          .
        </p>
        <p>
          Si trabaja por su cuenta, está inscrito en el programa de Permiso de
          cuidado pagado y ganó por lo menos $11,070 durante el año anterior,
          responda Sí.
        </p>
      </>
    ),
    dqMessage: `Lo sentimos, pero parece que es posible
    que no haya trabajado suficientes horas para calificar
    para el Permiso de cuidado pagado. Sin embargo, si
    sus horas fueron impactadas por COVID-19, es posible
    que todavía califique para el permiso.`,
  },
  qEventTime: {
    heading: `¿El evento calificador suyo o de su familiar ya ocurrió u
      ocurrirá pronto?`,
    accordions: [
      {
        heading: `Evento calificador`,
        body: (
          <>
            <p>
              Puede recibir un permiso pagado si usted o algún miembro de su
              familia experimentan un “evento calificador”.
            </p>
            <p>Los siguientes son ejemplos de eventos calificadores:</p>
            <ul>
              <li>
                Está por nacer un bebé en su familia o pronto recibirán a un
                menor adoptado o bajo su custodia.
              </li>
              <li>
                Está embarazada y su médico le ha ordenado reposo, así que no
                puede ir a trabajar.
              </li>
              <li>
                Deben hacerle una cirugía mayor o tiene una enfermedad o lesión
                grave.
              </li>
              <li>
                Está recibiendo tratamiento para un padecimiento de salud
                crónico, como diabetes o epilepsia.
              </li>
              <li>
                Está recibiendo tratamiento como interno en un centro
                especializado para controlar un problema de salud mental o de
                abuso de sustancias.
              </li>
              <li>
                Está cuidando de un familiar suyo que tiene una lesión o
                problema de salud fuerte.
              </li>
              <li>
                Un familiar suyo está en servicio militar activo y necesita
                tiempo para estar con él durante su descanso y recuperación.
              </li>
            </ul>
          </>
        ),
      },
    ],
    message: {
      true: `Con base en sus respuestas, puede recibir un Permiso de cuidado 
        pagado. Revise la <a href="/benefit-guide/"> guía de 
        beneficios</a> para asegurarse de tener todo lo que necesite al 
        solicitar y tomar el permiso y empiece el proceso.`,
      false: `De acuerdo con sus respuestas, puede recibir un Permiso de 
        cuidado pagado en el futuro. Asegúrese de tener 
        <a href="/es/get-ready-to-apply/">todo lo que necesite al solicitar 
        su permiso</a> y empiece el proceso.`,
    },
  },
};
