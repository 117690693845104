import React from "react";
import PropTypes from "prop-types";
import Intro from "./Intro";
import QWork from "./QWork";
import QEvent from "./QEvent";
import QFamily from "./QFamily";
import QEventTime from "./QEventTime";
import QApply from "./QApply";
import QHours from "./QHours";
import Result from "./Result";
import { REGION_ID, TOTAL_STEPS } from "./constants";
import content from "./content";

const defaultState = {
  step: 0,
  lastStep: 0,
  visualStep: 0,
  lastVisualStep: 0,
  isQualified: true,
};

export default class QualifyQuiz extends React.PureComponent {
  static propTypes = {
    lang: PropTypes.oneOf(["en", "es"]),
    applynowtext: PropTypes.string.isRequired,
    applynowurl: PropTypes.string.isRequired,
    applyfuturetext: PropTypes.string.isRequired,
    applyfutureurl: PropTypes.string.isRequired,
    learnurl: PropTypes.string.isRequired,
  };

  static defaultProps = {
    lang: "en",
    applynowtext: "Apply Now",
    applynowurl: "/",
    applyfuturetext: "Apply Now",
    applyfutureurl: "/",
    learnurl: "/",
  };

  state = defaultState;

  scrollToEl = document.getElementById("dialogScrollTo");

  componentDidUpdate(prevProps, prevState) {
    const { step: prevStep } = prevState;
    const { step } = this.state;

    if (prevStep !== step) {
      try {
        this.scrollToEl.scrollIntoView({ behavior: "smooth" });
      } catch {
        // Fallback for IE and Edge
        this.scrollToEl.scrollIntoView();
      }
    }
  }

  onSubmit = ({ step, visualStep, isQualified, message, applyNow }) => {
    if (isQualified === false) {
      this.setState((prevState) => ({
        isQualified,
        message,
        step: TOTAL_STEPS,
        visualStep: prevState.visualStep + 1,
      }));
    } else {
      this.setState((prevState) => ({
        step,
        message,
        applyNow,
        lastStep: prevState.step,
        visualStep,
        lastVisualStep: prevState.visualStep,
      }));
    }
  };

  onStart = () => {
    this.setState({ ...defaultState, step: 1, visualStep: 1 });
  };

  onBack = () => {
    this.setState((prevState) => ({
      step: prevState.lastStep,
      lastStep: prevState.lastStep - 1,
      visualStep: prevState.lastVisualStep,
      lastVisualStep: prevState.lastVisualStep - 1,
    }));
  };

  onClose = () => {
    const closeEl = document.getElementById("dialogClose");
    closeEl.click();
  };

  render() {
    const {
      lang,
      applynowtext: applyNowText,
      applynowurl: applyNowUrl,
      applyfuturetext: applyFutureText,
      applyfutureurl: applyFutureUrl,
      learnurl: learnMoreUrl,
    } = this.props;
    const { intro, qWork, qEvent, qFamily, qApply, qHours, qEventTime } =
      content[lang];
    const {
      step,
      lastStep,
      visualStep,
      lastVisualStep,
      isQualified,
      message,
      applyNow,
    } = this.state;
    const stepProps = {
      stepCurrent: step,
      onBack: this.onBack,
      onSubmit: this.onSubmit,
      isQualified: isQualified,
      lang: lang,
      visualStep: visualStep,
      visualStepCurrent: visualStep,
    };
    const applyLink = {
      text: applyNow ? applyNowText : applyFutureText,
      url: applyNow ? applyNowUrl : applyFutureUrl,
    };

    return (
      <div id={REGION_ID} role="region" className="m-quiz m-quiz--qualify">
        {step === 0 ? (
          <Intro
            onClick={this.onStart}
            lang={this.props.lang}
            content={intro}
          />
        ) : (
          <form id="quiz">
            <QWork step={1} content={qWork} {...stepProps} />
            <QEvent step={2} content={qEvent} {...stepProps} />
            <QFamily step={3} content={qFamily} {...stepProps} />
            <QApply step={4} content={qApply} {...stepProps} />
            <QHours step={5} content={qHours} {...stepProps} />
            <QEventTime step={6} content={qEventTime} {...stepProps} />
            {(!isQualified || step > 6) && (
              <Result
                lang={lang}
                isQualified={isQualified}
                message={message}
                onRestart={this.onStart}
                onClose={this.onClose}
                applyLink={applyLink}
                learnMoreUrl={learnMoreUrl}
              />
            )}
          </form>
        )}
      </div>
    );
  }
}
