import Sb from "../abstract/StatefulBehavior";

export default class AutoPlayWhenVisible extends Sb {
  constructor(el) {
    super();

    this.state = {
      visible: false,
      canPlayThrough: false,
      playing: false,
      hasPlayed: false,
    };
    this.el = el;
    this.initElClass = this.el.className;

    this.addVideoEvents();
  }

  update = () => {
    const { hasPlayed, playing, visible } = this.state;
    const canPlayThrough = this.el.readyState > 3;

    // 1) if video has played, stop observing and return
    if (hasPlayed) return this.unobserveVideo();
    // 2) if hasn't played but can't play through yet, return
    if (!canPlayThrough) return null;
    // 3) handle playback based on state.visible & state.playing
    if (visible) {
      !playing && this.el.play(); // visible but not playing yet
    } else {
      playing && this.el.pause(); // not visible but currently playing
    }

    Object.assign(this.el.style, {
      visibility: visible ? "visible" : "hidden",
    });
  };

  addVideoEvents() {
    this.runObserver();
    this.el.addEventListener("play", this.onPlay);
    this.el.addEventListener("pause", this.onPause);
    this.el.addEventListener("ended", this.onEnded);
  }

  removeVideoEvents() {
    this.el.removeEventListener("play", this.onPlay);
    this.el.removeEventListener("pause", this.onPause);
    this.el.removeEventListener("ended", this.onEnded);
  }

  unobserveVideo() {
    this.observer.unobserve(this.el);
    this.removeVideoEvents();
  }

  runObserver() {
    const options = {
      rootMargin: "0px",
    };
    this.observer = new IntersectionObserver(this.onIntersection, options);
    this.observer.observe(this.el);
  }

  onIntersection = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        this.setState({ visible: true });
      } else {
        this.state.visible && this.setState({ visible: false });
      }
    });
  };

  onPlay = () => {
    this.setState({ playing: true });
  };

  onPause = () => {
    // stop playing, and also set `state.hasPlayed` to true
    // if pause event is caused by end of playback
    const hasPlayed = this.el.currentTime === this.el.duration;
    this.setState({ playing: false, hasPlayed: hasPlayed });
  };

  onEnded = () => {
    // `pause` event isn't triggered by end of playback in Internet Explorer,
    // so in that case update state.hasPlayed from the `ended` event
    const hasPlayed = this.el.currentTime === this.el.duration;
    this.setState({ playing: false, hasPlayed: hasPlayed });
  };
}
