import React from "react";
import PropTypes from "prop-types";

const PlayIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 128 128"
      width="128"
      height="128"
      className={className}
      aria-hidden="true"
    >
      <ellipse
        className={`${className}--circle`}
        cx="64"
        cy="64"
        rx="61"
        ry="61.5"
      />
      <path d="M49.609 28.17v72.333l42.077-36.157z" fill="#fff" />
    </svg>
  );
};

PlayIcon.propTypes = {
  className: PropTypes.string,
};

export default PlayIcon;
