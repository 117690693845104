import PropTypes from "prop-types";

export const langShape = {
  lang: PropTypes.oneOf(["en", "es"]),
};

export const contentShape = {
  content: PropTypes.shape({
    heading: PropTypes.string.isRequired,
    body: PropTypes.node,
    accordions: PropTypes.arrayOf(
      PropTypes.shape({
        heading: PropTypes.string.isRequired,
        body: PropTypes.node.isRequired,
      }).isRequired
    ),
    message: PropTypes.shape({
      true: PropTypes.string,
      false: PropTypes.string,
    }),
    dqMessage: PropTypes.string,
  }).isRequired,
};

export const quizStepShape = {
  ...langShape,
  ...contentShape,
  isQualified: PropTypes.bool,
  onBack: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  step: PropTypes.number.isRequired,
  stepCurrent: PropTypes.number.isRequired,
  visualStep: PropTypes.number.isRequired,
  visualStepCurrent: PropTypes.number.isRequired,
};
