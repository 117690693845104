import React from "react";
import PropTypes from "prop-types";

function Head({ columnHeaders }) {
  return (
    <thead>
      <tr>
        <th className="m-premium-calculator__table-cell m-premium-calculator__table-cell--desktop"></th>
        {columnHeaders.map((header, index) => (
          <th key={index} className="m-premium-calculator__table-cell">
            {header}
          </th>
        ))}
      </tr>
    </thead>
  );
}

Head.displayName = "PremiumsCalculator.Table.Head";

Head.propTypes = {
  columnHeaders: PropTypes.arrayOf(PropTypes.string),
};

export default Head;
