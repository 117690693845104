import classNames from "classnames";
import Percolator from "../../helpers/Percolator";
import Sb from "../abstract/StatefulBehavior";
import ToggleSubmenu from "./ToggleSubmenu";
import debounce from "lodash/debounce";

export default class ControlSubmenus extends Sb {
  constructor(el, props, refs) {
    super();

    this.state = {
      open: false,
      submenuOpen: false,
    };

    this.el = el;
    this.refs = refs;
    this.initElClass = el.className;
    this.initMenuClass = refs.mobileMenu.className;

    this.instantiateSubmenus();
    this.update();
    this.bindToggle();
    this.bindResize();
  }

  get focusable() {
    return [this.refs.mobileToggle].concat(
      Array.from(
        this.refs.mobileMenu.querySelectorAll(
          'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])' // eslint-disable-line max-len
        )
      )
    );
  }

  get firstFocusable() {
    return this.focusable[0];
  }

  get lastFocusable() {
    return this.focusable[this.focusable.length - 1];
  }

  instantiateSubmenus() {
    const percolator = new Percolator();

    // Instantiate (and track) the submenus
    // with a callback that runs the "close"
    // function on all but the active hover
    this.submenus = this.refs.submenu.map((submenu, index) => {
      return percolator.loadBehavior(submenu, ToggleSubmenu, {
        id: index,
        onOpen: this.handleOpen,
        onActiveClose: this.handleActiveClose,
      });
    });
  }

  update = () => {
    const { mobileToggle, mobileMenu } = this.refs;
    const { open, submenuOpen } = this.state;

    this.el.className = classNames(this.initElClass, {
      "has-nav-untoggled": !open && !submenuOpen,
      "has-nav-toggled": open,
      "has-mobile-subnav-toggled": open && submenuOpen,
    });
    mobileToggle.setAttribute("aria-expanded", open ? "true" : "false");

    mobileMenu.className = classNames(this.initMenuClass, {
      "is-hidden": !this.state.open,
      "is-visible": this.state.open,
    });

    this.updateScrollLock();
  };

  bindToggle() {
    this.refs.mobileToggle.addEventListener("click", (event) => {
      event.preventDefault();
      event.stopPropagation();
      this.state.open ? this.closeMobileMenu() : this.openMobileMenu();
      this.trapFocus();
    });
  }

  bindResize() {
    // Set to closed state if at wider viewport width
    const debouncedInnerWidth = debounce(() => {
      if (!this.state.open) return null;

      const isMobileWidth = window.innerWidth < 850;
      !isMobileWidth && this.closeMobileMenu();
    }, 200);

    window.addEventListener("resize", debouncedInnerWidth);
  }

  handleOpen = (active) => {
    this.setState({ submenuOpen: true });
    this.closeOtherSubmenus(active);
  };

  handleActiveClose = () => {
    this.setState({ submenuOpen: false });
    this.closeOtherSubmenus();
  };

  openMobileMenu = () => {
    window.addEventListener("keyup", this.handleKeyUp);
    this.setState({ open: true });
  };

  closeMobileMenu = () => {
    window.removeEventListener("keyup", this.handleKeyUp);
    this.setState({ open: false, submenuOpen: false });
    this.closeOtherSubmenus();
    this.untrapFocus();
  };

  closeOtherSubmenus = (active) => {
    this.submenus.forEach((menu, index) => {
      if (index !== active) {
        menu.closeMenu();
      }
    });
  };

  handleKeyUp = (event) => {
    event.preventDefault();
    event.stopPropagation();

    this.handleEscape(event);
    this.handleTab(event);
  };

  handleEscape({ key, keyCode, which }) {
    if (key === "Escape" || key === "Esc" || keyCode === 27 || which === 27) {
      this.closeMobileMenu();
    }
  }

  handleTab({ key, keyCode, shiftKey }) {
    const isTabPressed = key === "Tab" || keyCode === 9;
    if (!isTabPressed) return;

    if (!this.focusable || !this.focusable.length) return false;

    const focusedItem = document.activeElement;
    const focusableIndex = this.focusable.indexOf(focusedItem);

    if (focusableIndex >= 0) return;

    shiftKey ? this.lastFocusable.focus() : this.firstFocusable.focus();
  }

  trapFocus() {
    if (!this.state.open || !this.focusable || !this.focusable.length) return;
    this.focusable[0].focus();
  }

  untrapFocus() {
    if (this.state.open || !this.focusable || !this.focusable.length) return;
    this.refs.mobileToggle.focus();
  }

  updateScrollLock() {
    const { classList } = document.body;
    const classNames = ["has-scroll-lock", "drawer-is-open"];

    classNames.forEach((className) => {
      if (this.state.open) {
        !classList.contains(className) && classList.add(className);
      } else {
        classList.contains(className) && classList.remove(className);
      }
    });
  }
}
