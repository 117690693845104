import LazyLoad from "./LazyLoad";
import ObjectFit from "./ObjectFit";
import ToggleDialog from "./ToggleDialog";
import DismissNotification from "./DismissNotification";
import SelectDownload from "./SelectDownload";
import AutoPlayWhenVisible from "./AutoPlayWhenVisible";

export default {
  LazyLoad,
  ObjectFit,
  ToggleDialog,
  DismissNotification,
  SelectDownload,
  AutoPlayWhenVisible,
};
