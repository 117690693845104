import Sb from "../abstract/StatefulBehavior";
import classNames from "classnames";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

export default class ToggleDialog extends Sb {
  constructor(el, props, refs) {
    super();
    this.state = {
      open: props.open ? true : false,
    };

    this.refs = refs;
    this.props = props;
    this.initDialogClass = refs.dialog.className;

    this.update();
    this.bindEvents();
  }

  get focusable() {
    return Array.from(
      this.refs.dialog.querySelectorAll(
        'summary, button:not(:disabled), [href], input, select, textarea, [tabindex]:not([tabindex="-1"])' // eslint-disable-line max-len
      )
    );
  }

  update = () => {
    const { open } = this.state;
    const { dialog, toggleOpen } = this.refs;

    dialog.className = classNames(this.initDialogClass, {
      "is-closed": !open,
      "is-open": open,
    });

    toggleOpen.setAttribute("aria-expanded", open ? "true" : "false");

    // disable/enable keyboard tabbing based on state.open
    this.focusable.forEach((el) => {
      el.setAttribute("tabindex", this.state.open ? 0 : -1);
    });
  };

  bindEvents() {
    const { toggleOpen, toggleClosed } = this.refs;

    toggleOpen.addEventListener("click", this.openDialog);
    toggleClosed.addEventListener("click", this.closeDialog);
  }

  closeOnEscape = ({ key, keyCode, which }) => {
    if (key === "Escape" || key === "Esc" || keyCode === 27 || which === 27) {
      this.closeDialog(event);
    }
  };

  disableScroll = () => {
    document.body.classList.add("has-scroll-lock");
    document.body.classList.add("dialog-is-open");
    disableBodyScroll(this.refs.scrollregion);
  };

  enableScroll = () => {
    document.body.classList.remove("dialog-is-open");
    document.body.classList.remove("has-scroll-lock");
    enableBodyScroll(this.refs.scrollregion);
  };

  openDialog = (event) => {
    event.preventDefault();
    event.stopPropagation();
    window.addEventListener("keyup", this.handleKeyUp);
    this.setState({ open: true });
    this.disableScroll();
    this.trapFocus();
  };

  closeDialog = (event) => {
    event.preventDefault();
    event.stopPropagation();
    window.removeEventListener("keyup", this.handleKeyUp);
    this.setState({ open: false });
    this.enableScroll();
    this.refs.toggleOpen.focus();
  };

  handleKeyUp = (event) => {
    event.preventDefault();
    event.stopPropagation();

    this.closeOnEscape(event);
    this.handleTab(event);
  };

  handleTab({ key, keyCode, shiftKey }) {
    const isTabPressed = key === "Tab" || keyCode === 9;
    if (!isTabPressed) return;

    const focusable = this.focusable;
    if (!this.focusable || !this.focusable.length) return false;

    const focusedItem = document.activeElement;
    const focusableIndex = focusable.indexOf(focusedItem);

    if (shiftKey && focusableIndex < 0) {
      focusable[focusable.length - 1].focus();
    } else if (focusableIndex < 0) {
      focusable[0].focus();
    }
  }

  trapFocus() {
    // conditionally set focus on firstFocus ref when state is open
    const { trapfocus = false } = this.props;
    const { open } = this.state;

    if (!open || !trapfocus || !this.focusable || !this.focusable.length)
      return;

    this.refs.dialog.addEventListener("transitionend", (e) => {
      if (e.target !== this.refs.dialog) return null;
      this.focusable[0].focus();
    });
  }
}
