import React, { useState } from "react";
import PropTypes from "prop-types";
import YouTube from "react-youtube"; // eslint-disable-line import/no-named-as-default
import classNames from "classnames";
import PlayIcon from "./PlayIcon";

function getSrcSet(sizes) {
  let srcSet = "";
  Object.keys(sizes).forEach((k) => {
    if (typeof sizes[k] === "string") {
      srcSet += sizes[k];
    } else if (k.includes("width")) {
      srcSet += ` ${sizes[k]}w, `;
    }
  });

  return srcSet;
}

function getDefaultSrcSet(sizes, url) {
  let srcSet = "";
  Object.keys(sizes).forEach((k) => {
    srcSet += `${url + k}.jpg ${sizes[k]}w, `;
  });

  return srcSet;
}

const VideoPlayer = ({ videoTitle, videoId, videoImage, videoSizes }) => {
  const [playVideo, setPlayVideo] = useState(false);
  const options = {
    width: "532",
    height: "360",
  };
  let player = null;

  const _handleOnReady = (event) => {
    player = event.target;
  };

  const _handleClick = (event) => {
    if (player) {
      player.playVideo();
      setPlayVideo(true);
    }
  };

  const defaultImgUrl = `https://img.youtube.com/vi/${videoId}/`;
  const defaultImgSizes = {
    maxresdefault: 1280,
    sddefault: 640,
    hqdefault: 480,
    mqdefault: 320,
  };

  const srcSet = videoImage
    ? getSrcSet(videoSizes)
    : getDefaultSrcSet(defaultImgSizes, defaultImgUrl);

  return (
    <div className="m-video">
      <div
        className={classNames({
          "m-video__preview": !playVideo,
          "m-video--is-hidden": playVideo,
        })}
      >
        <button className="m-video__play-button" onClick={_handleClick}>
          <PlayIcon className="m-video__play-icon" />
          <span className="a-hidden">Play Video {videoTitle}</span>
        </button>
        <img
          className="m-video__thumbnail"
          alt={videoTitle}
          src={
            videoImage ||
            `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`
          }
          srcSet={srcSet}
          sizes="(min-width:620px) 50vw, calc(100vw - 40)"
          width="100%"
          height="100%"
        />
      </div>
      <YouTube
        className={classNames({
          "m-video__wrapper": true,
          "m-video--is-hidden": !playVideo,
        })}
        videoId={videoId}
        opts={options}
        onReady={_handleOnReady}
      />
    </div>
  );
};

VideoPlayer.propTypes = {
  videoTitle: PropTypes.string.isRequired,
  videoId: PropTypes.string.isRequired,
  videoImage: PropTypes.string,
  videoSizes: PropTypes.object,
};

export default VideoPlayer;
