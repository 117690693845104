import "core-js/stable";
import "regenerator-runtime/runtime";
import "focus-visible";
import "intersection-observer";
import "./style/stylesheets/styles.scss";
import Percolator from "./javascript/helpers/Percolator";

import behaviors from "./javascript/behaviors";
import components from "./javascript/components";

const percolator = new Percolator();

percolator.loadBehaviors(behaviors.site);
percolator.loadBehaviors(behaviors.content);
percolator.loadComponents(components.content);
percolator.loadComponents(components.components);
