import React from "react";
import PropTypes from "prop-types";
import Button from "../Button";
import { buttonLabel } from "./helpers";
import { REGION_ID } from "./constants";
import { langShape } from "./shapes";

const Result = ({
  isQualified,
  message,
  onRestart,
  onClose,
  lang,
  applyLink,
  learnMoreUrl,
}) => {
  const handleBack = () => {
    onRestart();
  };
  const handleClose = () => {
    onClose();
  };

  return (
    <div className="m-quiz" aria-live="polite">
      <p
        className="m-quiz__result"
        dangerouslySetInnerHTML={{ __html: message }}
      />
      <div className="m-quiz__button-group">
        {!isQualified ? (
          <a className="m-button m-button--dark-blue" href={learnMoreUrl}>
            <span>{buttonLabel(lang, "learn")}</span>
          </a>
        ) : (
          <a className="m-button m-button--dark-blue" href={applyLink.url}>
            <span>{applyLink.text}</span>
          </a>
        )}
      </div>
      <div
        role="group"
        className="m-quiz__button-group m-quiz__button-group--end"
      >
        <Button
          ariaControls={REGION_ID}
          onClick={handleBack}
          label={buttonLabel(lang, "restart")}
        />
        <Button onClick={handleClose} label={buttonLabel(lang, "close")} />
      </div>
    </div>
  );
};

Result.propTypes = {
  ...langShape,
  isQualified: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  onRestart: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  applyLink: PropTypes.shape({
    text: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }),
  learnMoreUrl: PropTypes.string.isRequired,
};

export default Result;
