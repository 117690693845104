import React from "react";
import PropTypes from "prop-types";
import { currencyFormatter } from "../formulas";

export default function PremiumsCalculatorTableCell({ value }) {
  return (
    <td className="m-premium-calculator__table-cell">
      {typeof value === "number" ? currencyFormatter.format(value) : value}
    </td>
  );
}

PremiumsCalculatorTableCell.displayName = "PremiumsCalculator.Table.Cell";

PremiumsCalculatorTableCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
