import classNames from "classnames";

const SESSION_KEY = "hideNotification";

export default class DismissNotification {
  constructor(el, props, refs) {
    this.notificationDiv = el;
    this.refs = refs;

    // if UA doesn't support sessionStorage or blocks access to it,
    // hide dismiss button and bail
    try {
      this.session = sessionStorage.getItem(SESSION_KEY);
    } catch (error) {
      Object.assign(refs.toggleClosed.style, { display: "none" });
      return this.update();
    }

    this.update();
    this.bindEvents();
  }

  update = () => {
    // Show the notification if it hasn't been dismissed
    if (!this.session) {
      this.notificationDiv.classList.remove("m-notification--is-hidden");
    }
  };

  bindEvents() {
    const { toggleClosed } = this.refs;

    toggleClosed.addEventListener("click", this.closeNotification);
  }

  closeNotification = () => {
    this.notificationDiv.classList.add("m-notification--is-hidden");
    sessionStorage.setItem(SESSION_KEY, true);
  };
}
