import React from "react";
import PropTypes from "prop-types";

const PlusIcon = ({ className = "a-icon", small }) => {
  const smallSVG = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      width="20"
      height="20"
      className={className}
      aria-hidden="true"
    >
      <path className={`${className}--h-path`} d="M3.85 8.5h12.3v3H3.85z" />
      <path className={`${className}--v-path`} d="M8.5 3.85h3v12.301h-3z" />
    </svg>
  );

  const largeSVG = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      width="32"
      height="32"
      className="a-icon"
      aria-hidden="true"
    >
      <path className={`${className}--h-path`} d="M1.75 14.5h28.5v3H1.75z" />
      <path className={`${className}--v-path`} d="M14.5 1.75h3v28.5h-3z" />
    </svg>
  );

  return small ? smallSVG : largeSVG;
};

PlusIcon.propTypes = {
  className: PropTypes.string,
  small: PropTypes.bool,
};

export default PlusIcon;
